
.navItem {
    color: #232249;
    font-size: 20px;
    font-family: Tahoma;
    margin-left: 25px;
    opacity: 0.7;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 3px solid transparent;
}

.navItem:hover {
    color: #232249;
    border-bottom: 3px solid #232249;
    opacity: 1;
    text-decoration: none;
}

