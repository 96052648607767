
.formText {
    font-family: Tahoma;
}

.formPadding {
    padding-top: 20px;
}

.buttonUI {
    color: white;
    font-family: Tahoma;
}

.inputForm {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    width: 350px
}

.submitButton {
    background-color: #232249;
    border-width: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    opacity: 0.8;
}

.submitButton:hover {
    opacity: 1;
    background-color: #232249;
}

.rowStyle {
    margin-top: 30px;
}