
.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2em;
}

.background-gradient {
  background: linear-gradient(#fff, #E5523A);
}
