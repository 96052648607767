
.siteInProgress {
    color: #232249;
    font-size: 50px;
    font-family: Tahoma;
}

.signUp {
    color: #232249;
    font-size: 32px;
    font-family: Tahoma;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 40s linear;
        height: 5em;
        margin-bottom: 10px;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
